export const ApiEndPoints = {
  login: "api/login",
  forgotPassword: "/api/users/updatePassword",
  amenities: "api/amenities",
  users: "/api/users",
  categories: "/api/categories",
  categoriesAdd: "/api/categories/admin/add",
  stays: "/api/stays/admin/add",
  categoriesDelete: "/api/categories/admin/delete/",
  getLocation: "/api/locations/admin/getAll",
  getLocationById: "/api/locations/admin/",
  deleteLocation: "/api/locations/admin/delete/",
  updateLocation: "/api/locations/admin/update",
  adminAddLocation: "/api/locations/admin/add",
  allStay: "/api/stays/admin/getStaysList",
  stayById: "/api/stays/admin/findByStayId",
  deleteStay: "/api/stays/admin/delete/",
  updateHousePolicyDetails: "/api/stays/admin/updateHousePolicyDetails",
  editBasicDetails: "/api/stays/admin/update",
  editHousePolicy: "/api/stays/admin/updateHousePolicyDetails",
  editAmenity: "/api/stays/admin/updateAmenityDetails",
  editActivity: "/api/stays/admin/updateActivityDetails",
  editFacility: "/api/stays/admin/updateOtherFacilityDetails",
  editNearByPlaces: "/api/stays/admin/updateNearByPlaceDetails",
  deleteAmenity: "/api/stays/admin/deleteAmenityDetails/",
  deleteActivity: "/api/stays/admin/deleteActivityDetails/",
  deleteFacility: "/api/stays/admin/deleteOtherFacilityDetails/",
  deleteNearByPlaces: "/api/stays/admin/deleteNearByPlaceDetails/",
  deleteAccomodationTypes: "/api/stays/admin/deleteAccommodationType/",
  deletePricing: "/api/stays/admin/deletePricing/",
  deleteImage: "/api/stays/admin/deleteImage/",
  addAccomodationTypes: "/api/stays/admin/addNewAccommodationType",
  editAccomodationType: "/api/stays/admin/updateAccommodationType",
  editbedDetails: "/api/stays/admin/updateBedDetails",
  deletebedDetails: "/api/stays/admin/deleteBedDetails/",
  addBedDetails: "/api/stays/admin/addNewBedDetails",
  addPricing: "/api/stays/admin/addNewPricingDetails",
  addAmenity: "/api/stays/admin/addNewAmenityDetails",
  addImage: "/api/stays/admin/addNewImage",
  addActivity: "/api/stays/admin/addNewActivityDetails",
  addFacility: "/api/stays/admin/addNewOtherFacilityDetails",
  addNearByPlaces: "/api/stays/admin/addNewNearByPlaceDetails",
  dashboardCount: "/api/admin/dashboard/counts",
  dashboardLocation: "/api/admin/dashboard/locations",
  getAllBedType: "/api/bedTypes/admin/getAll",
  addBedType: "/api/bedTypes/admin/add",
  editBedType: "/api/bedTypes/admin/update",
  deleteBedType: "/api/bedTypes/admin/delete",
  getAllTestimonies: "/api/testimonials/admin/getAll",
  getTestimoniesById: "/api/testimonials/admin/get/",
  addTestimonies: "/api/testimonials/admin/add",
  editTestimonies: "/api/testimonials/admin/update",
  deleteTestimony: "/api/testimonials/admin/delete/",
  updatePricing: "/api/stays/admin/updatePricingDetails",
  addChildrensPayment: "/api/stays/admin/addNewChildrenPaymentDetails",
  editChildrensPayment: "/api/stays/admin/updateChildrenPaymentDetails",
  deleteChildrensPayment: "/api/stays/admin/deleteChildrenPayment/",
  setDefaultImage: "/api/stays/admin/setDefaultImage",
  addNewReadMoreDetails: "/api/locations/admin/addNewReadMoreDetails",
  updateReadMoreDetails: "/api/locations/admin/updateReadMoreDetails",
  deleteReadMore: "/api/locations/admin/deleteReadMore/",
};
