import { ApiEndPoints, NetworkManager } from "../network";

export class StaysService {
  static addStays = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.stays,
      data: data,
    });
  };
  static addAccomodationTypes = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addAccomodationTypes,
      data: data,
    });
  };
  static addPricingDetails = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addPricing,
      data: data,
    });
  };
  static addChildrensPayment = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addChildrensPayment,
      data: data,
    });
  };
  static addAmenity = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addAmenity,
      data: data,
    });
  };
  static addActivity = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addActivity,
      data: data,
    });
  };
  static addFacility = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addFacility,
      data: data,
    });
  };
  static addNearByPlaces = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addNearByPlaces,
      data: data,
    });
  };
  static addImage = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addImage,
      data: data,
    });
  };
  static getAllStays = (page = 1, limit = 10, pages = 3) => {
    return NetworkManager.getInstance().appRequest({
      method: "get",
      url: ApiEndPoints.allStay,
      params: { page, pages, limit },
    });
  };
  static getStaysById = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "get",
      url: ApiEndPoints.stayById + "/" + id,
      data: { ...id },
    });
  };
  static deleteStays = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteStay + id,
      data: { ...id },
    });
  };
  static deleteAmenity = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteAmenity + id,
      data: { ...id },
    });
  };
  static deleteActivity = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteActivity + id,
      data: { ...id },
    });
  };
  static deleteFacility = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteFacility + id,
      data: { ...id },
    });
  };
  static deleteNearByPlaces = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteNearByPlaces + id,
      data: { ...id },
    });
  };
  static deleteAccomodationTypes = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteAccomodationTypes + id,
      data: { ...id },
    });
  };
  static deletePricingDetails = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deletePricing + id,
      data: { ...id },
    });
  };
  static deleteImage = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteImage + id,
      data: { ...id },
    });
  };

  static deleteBedDetails = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deletebedDetails + id,
      data: { ...id },
    });
  };
  static updateBasicDetails = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editBasicDetails,
      data: data,
    });
  };
  static updateHousePolicy = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editHousePolicy,
      data: data,
    });
  };
  static updateAmenity = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editAmenity,
      data: data,
    });
  };
  static updateActivity = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editActivity,
      data: data,
    });
  };
  static updateFacility = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editFacility,
      data: data,
    });
  };
  static updateNearByPlaces = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editNearByPlaces,
      data: data,
    });
  };
  static updateAccomodationType = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editAccomodationType,
      data: data,
    });
  };
  static updateBedDetails = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editbedDetails,
      data: data,
    });
  };
  static updatePricing = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.updatePricing,
      data: data,
    });
  };
  static updateChildrensPayment = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: ApiEndPoints.editChildrensPayment,
      data: data,
    });
  };
  static deleteChildrensPayment = (id) => {
    return NetworkManager.getInstance().appRequest({
      method: "delete",
      url: ApiEndPoints.deleteChildrensPayment + id,
      data: { ...id },
    });
  };
  static addBedDetails = (data) => {
    return NetworkManager.getInstance().appRequest({
      method: "post",
      url: ApiEndPoints.addBedDetails,
      data: data,
    });
  };
  static setDefaultImage = (stay, image) => {
    return NetworkManager.getInstance().appRequest({
      method: "put",
      url: `${ApiEndPoints.setDefaultImage}/${stay}/${image}`,
    });
  };
}
